<template>
  <div id="logindiv">
    <div id="login">
      <h3 style="margin-bottom:20px">
        <span>{{webname}}</span>系统后台管理
      </h3>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-user" type="text" v-model="account" placeholder="请输入用户名" />
      </el-row>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-key" type="password" v-model="password" placeholder="请输入密码" @keyup.enter.native="submitForm" />
      </el-row>
      <el-row class="margin-large-bottom text-center">
        <el-button type="primary" @click="submitForm">登录</el-button>
      </el-row>
    </div>
    <div class="text-center" style="position:absolute;bottom:0;width:100%;"><a href="http://beian.miit.gov.cn">鲁ICP备20002132号-1</a></div>
  </div>  
</template>

<script>
import { mapActions } from "vuex";
import PuzzleVerification from "../../plugins/verify.js";
export default {
  components: {
    PuzzleVerification
  },
  data() {
    return {
      account: "",
      password: "",
      webname:this.WEBNAME,
    };
  },
  mounted() {
    // this.request();
  },
  methods: {
    ...mapActions({
      login: "auth/login"
    }),
    submitForm() {
      if (this.account == "") {
        this.$message({
          type: "error",
          message: "请输入用户名"
        });
        return;
      }
      if (this.password == "") {
        this.$message({
          type: "error",
          message: "请输入密码"
        });
        return;
      }
      this.$axios({
        method: "post",
        url: this.HOST + "/admin/login/login",
        data: {
          account: this.account,
          password: this.password,
        }
      }).then(res => {
        if (res.data.status == 1) {
          sessionStorage.setItem("admin_id",res.data.admin_id);
          sessionStorage.setItem("admin_name",res.data.username);
          sessionStorage.setItem("admin_token",res.data.token);
          sessionStorage.setItem("admin_login",true);
          sessionStorage.setItem("admin_menulist",JSON.stringify(res.data.menulist));
          sessionStorage.setItem("admin_urllist",JSON.stringify(res.data.urllist));
          this.$router.push(res.data.urllist[0]);
        } else if (res.data.status == 0) {
          this.$alert(res.data.info, "错误");
        }
      });
    }
  }
};
</script>

<style>
#logindiv {
  background-image: url(../../assets/images/admin/login/login_bg.jpg);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#login {
  width: 600px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 25%;
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
}
</style>
